body {
  margin: 0;
  background: #f4f4f4;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

.wrap {
  width: 90%;
  margin: auto;
}

.hgap {
  display: inline-block;
  width: 10px;
}

.gap {
  height: 10px;
}

.flex {
  display: flex;
  &.jcsb {
    justify-content: space-between;
  }
  &.ci {
    align-items: center;
  }
  &.jcr {
    justify-content: flex-end;
  }
  &.jcc {
    justify-content: center;
  }
}

.card {
  box-shadow: var(--shadow);
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin: 7px 0;
}
.form-field-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
