section#login {
  background: url("../../assets/images/login_bg.jpg") no-repeat;
  // video source: https://unsplash.com/photos/pYWuOMhtc6k
  background-size: cover;
  background-position: center;

  height: 100vh;
  width: 100vw;
  position: relative;
  .login-controls {
    header {
      .title {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
    border: 1px solid #ddd;
    border-radius: 6px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    width: 300px;
    padding: 40px 20px;
    padding-top: 20px;
  }
}
