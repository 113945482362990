.blog-item-img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 4px;
}

section#dashboard {
  .dashboard-left {
    @media (max-width: 1000px) {
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 170px;
      img {
        transition: 0.3s;
        padding: 30px 0;
        width: 89px;
        max-width: 85%;
      }
    }
  }

  .dashboard-right {
    flex: 1;
  }
}

nav {
  border-bottom: 1px solid #ddd;
  background: #fff;
  width: 100%;
  .flex {
    height: 70px;
  }
}
